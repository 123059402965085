.sendmoney-header {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #333333;
}

.day-and-date {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.sendmoney-left {
  width: 35%;
}

.sendmoney-bigheader {
  font-weight: 600;
  font-size: 54px;
  line-height: 69px;
}

.sendmoney-bigheader + p {
  font-size: 18px;
  line-height: 27px;
  color: #252525;
  max-width: 444px;
}

@media screen and (max-width: 970px) {
  .sendmoney-formarea {
    justify-content: center !important;
  }

  .sendmoney-left {
    display: none;
  }
}
