// change bootstrap colors
$theme-colors: (
  "primary": #008080,
);

@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Poppins");

// Variables
$text-Poppins: "Poppins";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  // default font
  font-family: "Poppins", Arial, Helvetica, sans-serif;
}

.is-invalid {
  background-image: none !important;
}
.user-dashboard {
  width: calc(100vw - 202px);
  min-height: 100vh;
  margin-left: auto;
  background: #f3f5fc;
}

//for datepicker
@import "react-datepicker/dist/react-datepicker.css";

@media screen and (max-width: 970px) {
  .user-dashboard {
    width: unset;
    margin-left: unset;
    margin-top: 80px;
  }
}

// When printing invoice at /transitions/:id
@media print{
	.hide-on-print{
		display: none !important;
	}
  .user-dashboard {
    width: unset;
    margin-left: unset;
    margin-top: 80px;
  }
}