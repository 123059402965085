.naira-card-demo,
.card-in-process {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 250px;
    width: 473px;
    border-radius: 25px;
    cursor: pointer;
  }

  .card-in-process {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/card-in-process.png");
  }

  .card-in-process-type {
    position: absolute;
    top: 24px;
    left: 24px;
  }

  .card-in-process-text {
    font-size: 24px;
    line-height: 36px;
  }

  .naira-card-demo.active {
    background-image: url("../../assets/naira-card.png");
  }

  .naira-card-demo.inactive {
    background-image: url("../../assets/deactivated-card.png");
  }

  .naira-card-demo.details {
    background-image: url("../../assets/card-details-bg.png");
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .checkbox-length {
    width: 40px;
    height: 20px;
  }

  .card-switch.custom-switch .custom-control-label::after {
    background-color: #489494 !important;
  }

  .card-switch.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #ff857c !important;
  }

  .card-switch .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff !important;
    border-color: #fff !important;
    background-color: #fff !important;
  }

  .card-switch .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
  }

  .switch-container {
    height: 25px;
  }

  .card-field {
    color: #658383;
  }

  .card-field.left {
    text-align: left;
  }

  .card-field.right {
    text-align: right;
  }

  .card-field-label {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 10px;
  }

  .card-field-value {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 0;
  }
    
  .card-12{
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }

  .naira-card-demo .card-amount{
    font-size: 24px;
    line-height: 36px;
    margin: 10px 0 48px;
  }

  .naira-card-demo .card-number{
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 30px;
  }

  .naira-card-demo .card-exp{
    font-size: 14px;
    line-height: 21px;
  }

  .cards-modal-content {
    border-radius: 10px;
    border: none;
  }

  .cards-input-field {
    width: 100% !important;
    height: 54px !important;
  }

  .cards-form-cancel {
    color: #008080;
    display: inline-block;
    margin-right: 50px;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    cursor: pointer;
  }

  .cards-form-submit {
    padding: 15px 42px;
    box-shadow: 0px 10px 25px 5px rgba(0, 128, 128, 0.2);
    border-radius: 10px;
  }

  // change bootstrap checkbox color
  .card-act .card-switch.custom-switch .custom-control-label::after{
    background-color: #008080;
  }

  .add-card{
    width: 473px;
    height: 250px;
    border-radius: 10px
  }

  .cards-container{
    gap: 2rem;
  }

  .cards-modal-wrapper {
    padding: 40px 30px 31px;
  }

  .cards-header {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #333333;
    margin-bottom: 14px;
  }

  .cards-header.new-card-header {
    margin-bottom: 10px;
  }

  .cards-footer {
    margin-top: 24px;
  }

  .new-card-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .new-card-left {
    width: 35%;
  }

  .new-card-right {
    width: 60%;
    margin-top: 40px;
  }

  .new-card-left-content {
    max-width: 444px;
  }

  .new-card-title {
    font-weight: 600;
    font-size: 64px;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 15px;
  }

  .new-card-text {
    font-size: 18px;
    line-height: 27px;
    color: #252525;
  }

   .new-card-title .title-red {
    color: #db261b;
  }

  .card-in-transit {
    padding: 35px 45px 20px;
  }

  .card-in-transit-header {
    font-weight: 600;
    font-size: 36px;
    line-height: 108.5%;
    color: #008080;
    margin-bottom: 10px;
  }

  .card-in-transit-text {
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #252525;
  }

  .card-in-transit-text.notice {
    margin-bottom: 6px;
  }

  .card-in-transit-text.mail {
    font-style: italic;
    margin-bottom: 0;
  }

  @media screen and (max-width: 1320px) {
    .new-card-title {
      font-size: 50px;
    }
  }

  @media screen and (max-width: 1073px) {
    .new-card-title {
      font-size: 40px;
    }
  }

  @media screen and (max-width: 970px){
    .menu-bars {
      margin-left: 0;
    }

    .cards-container {
      align-items: center;
      justify-content: center;
    }

    .new-card-wrapper {
      flex-direction: column;
    }

    .new-card-left {
      width: 100%;
    }

    .new-card-right {
      width: 100%;
    }

    .naira-card-demo, 
    .card-in-process,
    .add-card{
      width: 374px;
    }   

    .naira-card-demo .card-amount,
    .card-in-process-text {
        font-size: 18px;
        line-height: 27px;
    }


    .naira-card-demo .card-number{
        font-size: 16px;
        line-height: 24px;
    }
   .naira-card-demo .card-exp{
        font-size: 13px;
        line-height: 19px;
    }

    .new-card-left-content {
      display: none;
    }
  }

  .date{
      background: none;
      border: none;
  }

  .date{
    display: inline-block;
    position: relative
  }

  .hide{
      display: none;
  }


  @media screen and (max-width: 575.98px) {
    .cards-modal-wrapper {
      padding: 20px;
    }
  }