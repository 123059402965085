.recurring-card {
  border-radius: 10px;
  width: 22rem;
  height: 12rem;
}

.recurring-card_number {
  font-size: 18px;
}

.recurring-card_stop {
  cursor: pointer;

  &:hover {
    color: red;
  }
}

.recurring-cards_mobile {
  display: none;
}

@media screen and (max-width: 970px) {
  .recurring-cards_mobile {
    display: block;
  }
}
