.airtime-navbar {
  flex: 2;
  h2 {
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
  }
  ul li {
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    padding: 0.5rem 0rem 0.5rem 1.5rem;
    margin: 0.5rem 0;
    list-style: none;
    cursor: pointer;

    a {
      text-decoration: none;
      color: inherit;
    }

    &:hover {
      background: #f3f5fc;
      border-left: 5px solid #008080;
    }
  }
}

.highlighted-nav-item {
  background: #f3f5fc;
  border-left: 5px solid #008080;
}

.cropper {
  position: relative;
  width: 100%;
  height: 20rem;
}

.referral-header {
  font-size: 16px;
  line-height: 24px;
}

.referral-msg {
  font-size: 14px;
  line-height: 21px;
}

.referral-card {
  width: 340px;
  height: 117px;
  background: #f3f5fc;
  border: 1px solid #d6dcdc;
  box-sizing: border-box;

  div{
   h6{
    font-size: 12px;
    line-height: 18px;
    font-weight: bold;
   }
   p{
    font-size: 24px;
    line-height: 36px;
   }
  }
}

.copy-icon-container{
  font-size: 32px;
  //cursor: pointer;
  color: #79B0B0;
}



@media screen and (max-width: 970px) {
  .airtime-container {
    width: 92vw;
    margin: 0rem auto;
  }

  .airtime-navbar {
    margin-right: 1rem !important;

    h2 {
      font-size: 16px;
      line-height: 24px;
    }

    ul li {
      font-size: 14px;
      line-height: 21px;
    }
  }
}

@media screen and (max-width: 321px) {
  .airtime-container {
    width: 92vw;
    margin: 1rem auto;
  }

  .airtime-navbar {
    margin-right: 4px !important;

    h2 {
      font-size: 14px;
      line-height: 20px;
    }

    ul li {
      font-size: 12px;
      line-height: 18px;
    }
  }

  //////////
   
}

@media screen and (min-width: 320px) {

.billsContainer {
    height: 15rem;
    padding: 1rem;
    justify-content: center;
 
    width: 80vw;
      background: white;
    cursor: pointer;
    margin: 1rem auto;
    text-decoration: none;
  }
  .billsContainer :hover{
    text-decoration: none;
  }
    .title {
      font-size: 1rem;
      font-weight: bold;
  
      &-sub {
        font-size: 0.8rem;
      }
    }

    .title:hover{
      text-decoration: none;
    }

   
}
@media screen and (min-width: 768px) {

  .billsContainer {
    height: 18rem;
    padding: 1rem;
    background: white;
    justify-content: center;
    width: calc(100%/5);
    cursor: pointer;
    margin: 1rem 1rem;
 
  }
}

@media screen and (min-width: 1440px) {

  .billsContainer {
      height: 18rem;
      padding: 2rem 2rem;
      background: white;
      justify-content: center;
      margin: 10px;
      flex-grow: 1;
      width: calc(100%/6);
      cursor: pointer;
  
  
    }
  
    .title {
      font-size: 1rem;
      font-weight: bold;
  
      &-sub {
        font-size: 0.8rem;
      }
    }
}