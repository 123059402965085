.page-404 {
  background-color: #79b0b0;
  padding: 30px 25px;
  width: 100vw;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-image: url("../../assets/404-frame-main.svg"),
    url("../../assets/404-frame-overlap.svg");
  background-position: bottom, bottom;
  background-repeat: no-repeat, no-repeat;
  background-size: 100%;

  @media only screen and (min-height: 850px) {
    align-items: center;
  }

  &-main {
    display: flex;
    align-items: center;
    color: white;
    line-height: 1.5;

    @media only screen and (min-height: 850px) {
      padding-top: 0;
    }

    @media only screen and (max-width: 769px) {
      display: block;
      width: 100%;
    }

    &-heading {
      font-size: 144px;
      padding: 65px 50px 65px 0;
      margin-right: 50px;
      border-right: 3px solid white;

      @media only screen and (max-width: 864px) {
        margin-right: 30px;
        padding-right: 20px;
        font-size: 80px;
      }

      @media only screen and (max-width: 769px) {
        border-right: 0;
        padding: 0;
        margin: 0;
        margin-bottom: 10px;
        text-align: center;
      }
    }

    &-content {
      @media only screen and (max-width: 769px) {
        text-align: center;
      }

      h2 {
        font-size: 64px;
        text-transform: uppercase;
        margin-bottom: 10px;
        @media only screen and (max-width: 769px) {
          font-size: 40px;
        }
      }

      p {
        text-align: left;
        max-width: 449px;
        font-size: 24px;

        @media only screen and (max-width: 769px) {
          text-align: center;
          max-width: 100%;
          font-size: 16px;
        }
      }
    }

    &-link {
      color: white;
      font-size: 18px;
      display: flex;
      align-items: center;
      display: inline-block;
      margin-bottom: 10px;

      &-text {
        padding-left: 15px;
      }

      &:hover {
        color: white;
        text-decoration: none;
      }
      @media only screen and (max-width: 769px) {
        font-size: 14px;
      }
    }
  }
}
