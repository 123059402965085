.user-dashboard_left {
  width: 100%;
}

.user-dashboard_left h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #333333;
}

.user-dashboard_left h2 {
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}

.user-dashboard_right {
  width: 414px;
  min-height: 1535px;
  background: #ffffff;
  overflow-x: hidden;
  overflow-y: auto;
}

.total-balance-container{
  background-color: #fff;
  border-radius: 5px;
}

.total-balance{
  font-size: 24px !important;

  span{
    white-space: nowrap;
  }
}

.userdash-card_left,
.userdash-card_right {
  width: 48%;
  padding: 0rem 1rem 1rem 1rem;
  background: #fff;
  border-radius: 10px;
}

.userdash-card_left{
  min-height: 130px;
  overflow: auto;
}

.userdash-card_right{
  height: 130px;
  position: relative;
}

.fund-btn {
  width: 144px;
  height: 40px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.acc-name {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #313e3e;
}
.acc-bal {
  font-size: 24px;
  line-height: 36px;
  color: #313e3e;
}

.hide-bal {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #313e3e;
}
.bank-details {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #313e3e;
}

.bank-name,
.bank-number {
  font-size: 12px;
  line-height: 18px;
  color: hsl(0, 0%, 45%);
}
.bank-name + p,
.bank-number + p {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #011c34;
}
.btn-copy {
  width: 79px;
  height: 40px;
  border: 1px solid #008080;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

.hide-right-transactions{
  display: none;
}

.copyright-small-screen{
display: none;
font-size: 12px;
line-height: 18px;
color: #000000;
text-align: center;
}

.copy-toast{
  position: absolute;
  top: 10%;
  left: 75%;
  z-index: 10;
}

.copy-toast-text{
  padding: 0.5rem;
  font-size: 12px;
}

@media screen and (max-width: 1024px) {
  .user-dashboard_right {
    display: none;
  }
  .userdash-card_left,
.userdash-card_right{
  width: 95%;
}
  .userdash-cards {
    gap: 1rem;
    align-items: center !important;
    justify-content: center !important;
  }

  .hide-right-transactions,.copyright-small-screen{
    display: block;
  }
  .right-transactions{
    margin: 2rem 1rem;
  }
}
