.investment {
    padding: 3rem 5rem;
  }
  .investment-header{
    display: flex;
  }
  .investment-mainheader{
    font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  color: #333333;
  margin-bottom: 1rem;
  }
  .investment-call-to-action{
    font-size: 18px;
  line-height: 21px;
  color: #313E3E;
  }
  .investment-icon{
    content: url("../../assets/investment-icon.svg");
    border-radius:50%;
  }
  .investment-tags{
    display: flex;
    margin-top: 1.5rem;
    margin-left: 4rem;
    cursor: pointer;
  }
  .investment-tags_item{
    display: flex;
      height: 2rem;
  }
  .investment-text{
    margin-left:0.5rem;
    margin-right: 2rem;
    font-size: 14px;
  line-height: 16px;
  color: #000000;
  }
  .dim{
    opacity: 0.5;
  }

  .investment-date{
      display: none;
  }

// .topup{
//   white-space: nowrap;
// }


  @media screen and (max-width: 970px) {
      .investment{
          padding: 1rem;
      }
      .investment-tags,.investment-call-to-action{
          display: none;
      }
      .investment-date{
          display: block;
      }
  }