.transactions-list{
    width: 604px;
height: 646px;
border-radius: 10px;
overflow: auto;
}

.transactions-list h3{
    font-size: 18px;
line-height: 21px;
}

.transactions-list div h5{
    font-weight: 500;
font-size: 18px;
line-height: 21px;
}

.transactions-list div p{
    font-size: 14px;
line-height: 16px;
}

@media screen and (max-width: 1024px) {
    .transactions-list{
        width: 90vw;
    }     
    .transactions-area{
        gap: 1rem;
    }
}