.total-container {
  width: 100%;
  height: 345px;
  background-color: #fff;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 10px;
  display: flex;
}
.total-container_left {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  padding-left: 4rem;
  padding-right: 4rem;
  flex-direction: column;
}
.big-circle {
  width: 190px;
  height: 190px;
  background: #ffffff;
  border: 20px solid #008080;
  box-sizing: border-box;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #313e3e;
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
}
.total-text {
  font-size: 12px;
  line-height: 14px;
  font-weight: normal;
}
.total-small-circle {
  width: 15px;
  height: 15px;
  background: #008080;
  border-radius: 50%;
}
.red-circle {
  background-color: #db261b;
}
.ash-circle {
  background: #658383;
}
.bullet-points {
  display: flex;
}
.bullet-text {
  font-size: 12px;
  line-height: 14px;
  color: #828282;
  margin-left: 0.5rem;
  align-self: center;
}
.bullet-container {
  display: flex;
  gap: 1rem;
}
.green-text {
  font-size: 14px;
  line-height: 16px;
  color: #4f4f4f;
  margin-top: 1rem;
}
.right-text {
  font-size: 18px;
  line-height: 21px;
  color: #658383;
}
.total-left {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  margin-left: 2rem;
}

@media screen and (max-width: 970px) {
  .total-container {
    display: none;
  }
}
