.edit-profile {
  flex: 5;

  h3 {
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
  }
  p {
    font-size: 14px;
    line-height: 21px;
  }
}

@media screen and (max-width: 970px) {
  .edit-profile {
    h3 {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .edit-profile-input-group .reg-input {
    width: 100%;
  }

  .profile-state{
    display: block !important;
  }
  .profile-modifier{
    background-color: #fff;
  }
  // .profile-dob{
  //   width: 40vw;
  // }
}
