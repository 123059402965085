.airtime-container{
  width: 80vw;
  background: white;
  margin: auto;
}
@media screen and (min-width: 768px) {
.airtime-container {
    width: 60vw;

  }
}

@media screen and (min-width: 1440px) {
.airtime-container {
    width: 50vw;
   
  }
 
}