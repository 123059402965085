.table-area {
  position: relative;
}

.transactions-spinner {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.clear-btn::after {
  content: "Reset";
}

// .table-data{
//    // word-wrap: break-wor
// }

.table-header {
  white-space: nowrap;
}

// .transaction-data-table {
//     border-collapse: collapse;
//     margin: auto;
//   }

.table-row {
  cursor: pointer;

  &:hover {
    background-color: rgb(217, 235, 235);
  }
}

.table-width {
  max-width: 20rem;
}

.table-dot {
  width: 10px;
  height: 10px;
}

.no-break {
  white-space: nowrap;
}

.check-som {
  position: absolute;
  top: 100%;
  left: 40%;
}

// pagination
.pagination {
  margin: 15px 0 15px auto;
  display: flex;
  justify-content: flex-end;
  list-style: none;
  outline: none;
  color: #fff;
}
.pagination > .active > a {
  background-color: #fff;
  border-color: #fff;
  color: #008080;
}
.pagination > li > a {
  border: 1px solid #008080;
  padding: 8px 15px;
  outline: none;
  cursor: pointer;
  background-color: #008080;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: rgba(0, 128, 128, 0.7);
  border-color: rgba(0, 128, 128, 0.7);
  color: #fff;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #fff;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

//custom
.pagination > li:first-child > a {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.pagination > li:last-child > a {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
