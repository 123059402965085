.hide-nav {
  display: none;
}

.navbar {
  background-color: #fff;
  height: 80px;
  display: none;
  justify-content: space-between;
  align-items: center;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
}

.nav-menu {
  background-color: #fff;
  width: 176px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  transition: 850ms;
  z-index: 1001;
}

.nav-menu_img {
  width: 100px;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 48px;
  width: 170px;
}

.nav-text a,
.nav-logout span {
  text-decoration: none;
  color: #489494;
  font-size: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 4px;
  border-radius: 10px;
  height: 42px;
}

.nav-text a:hover,
.menu-path a {
  background: #d7ecec;
  font-weight: bold;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #fff;
  width: 100%;
  height: 80px;
  display: none;
  justify-content: flex-start;
  align-items: center;
}

.menu-span {
  margin-left: 16px;
}
.nav-menu ul {
  height: 100%;
}

.nav-logout span {
  color: red;
  align-items: center;
  cursor: pointer;
}
.nav-logout span:hover {
  background: rgb(236, 213, 213);
  font-weight: bold;
}
@media screen and (max-width: 970px) {
  .menu-bars {
    margin-left: 0;
  }
  .nav-menu {
    left: -100%;
  }

  .navbar-toggle {
    display: flex;
  }

  .navbar {
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 50;
  }
  .nav-menu_logo {
    display: none !important;
  }

  .nav-menu {
    overflow: auto;
  }
}
