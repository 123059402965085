.investment-card-container {
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 2rem 0;
}
.investment-card {
    width: 294px;
    height: 351px;
  background: #ffffff;
  border-radius: 10px;
  background-image: url("../../../assets/card-deco.png");
  background-repeat: no-repeat;
  background-position: top;
  color: #ffffff;
  margin-bottom: 3rem;
}
.investment-card-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-top: 1rem;
}
.investment-card-interest {
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  margin: 0rem;
  padding: 0rem;
}
.investment-card-interest,
.investment-card-days {
  margin-top: 0.5rem;
}
.investment-card-days,
.investment-card-feature,
.investment-card-button {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}
.investment-card-features-container {
  margin-top: 3rem;
}
.investment-card-feature {
  color: #7c7c7c;
  margin-top: 1rem;
}
.investment-card-button {
  height: 46px;
  width: 176px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #008080;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-top: 1rem;
}
.investment-card-check-icon {
  color: #3bb54a;
  font-size: 10px;
  margin-right: 0.3rem;
}
.percent {
  font-size: 24px;
}

@media screen and (max-width: 970px) {
    .investment-card-container{
        justify-content: center;
    }
}
