.account-cards-container {
  max-width: 1320px;
  margin: 0 auto;
}
.account-card {
  border-radius: 25px;
  // height: 400px;
  box-shadow: 0 0 5px rgba(0,0,0,0.01);
  background-color: white;
  overflow: hidden;
  margin-bottom: 40px;
}

.account-card-top {
  padding: 20px;
  background-color: #008080;
  color: white;
  height: 125px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;

  &_bankname{
    font-size: 16px;
  }
}

.account-card-body {
  padding: 25px 20px;
}

.account-card-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
}

.account-card-field-name,
.account-card-field-value {
  margin-bottom: 0;
}

.account-card-field-name {
  font-weight: 300;
}

.account-card-field-value {
  text-transform: capitalize;
  color: #489494;
  font-weight: 600;
}

.account-card-footer {
  padding: 0 20px 35px;
}

.account-card-btn-copy {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-account-title {
  font-weight: 600;
  font-size: 44px;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 15px;
}

.new-account-title .title-red {
  color: #db261b;
}

@media screen and (max-width: 1073px) {
  .new-account-title {
    font-size: 30px;
  }
}