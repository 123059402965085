.invest-review-header {
  h2 {
    font-size: 18px;
    line-height: 21px;
    font-weight: bold;
  }
  p{
    font-size: 16px;
    line-height: 19px;
  }
}
