.change-password_alert {
  font-size: 14px !important;
}

.settings-small-header {
  font-size: 18px;
  line-height: 27px;
  font-weight: bold;
}

@media screen and (max-width: 970px) {
}
