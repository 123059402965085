.form1{
    gap: 2rem;
}

.invest-form-msg {
  h2 {
    font-size: 18px;
    line-height: 21px;
    font-weight: bold;
    margin-top: 1rem;
  }
  p{
    font-size: 14px;
    line-height: 16px;
  }
}

.invest-form{
  :first-child{
      margin-right: 0.5rem;
  }
}

.investment-naira{
height: 43px;
background: #f3f5fc;
border: 1px solid #d6dcdc;
align-self: flex-end;
align-items: center;
padding: 8px 10px 0 10px;
margin-right: -0.4rem !important;
}