.register {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  background: #f3f5fc;
  min-height: 100vh;
}

.reg-msg h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
}

.form-container {
  background: #ffffff;
  box-shadow: 1px 4px 30px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 2rem 4rem 0 4rem;
  margin-bottom: 5rem;
}

.reg-label {
  font-size: 16px;
  line-height: 14px;
}

.reg-input {
  width: 301px;
  height: 43px;
  background: #f3f5fc;
  border: 1px solid #d6dcdc;
  box-sizing: border-box;
  border-radius: 5px;
}

.reg-btn {
  width: 650px;
  height: 40px;
  border-radius: 10px;
}

.reg-input-groups {
  gap: 2rem;
  flex-wrap: wrap;
}

.reg-bottom-text {
  font-size: 14px;
  line-height: 16px;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 12px;
  line-height: 14px;
  color: #a4a4a4;
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 12px;
  line-height: 14px;
  color: #a4a4a4;
}
:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 12px;
  line-height: 14px;
  color: #a4a4a4;
}
:-moz-placeholder {
  /* Firefox 18- */
  font-size: 12px;
  line-height: 14px;
  color: #a4a4a4;
}

//for formik errors
.error {
  position: absolute;
  color: red;
  font-size: 0.8rem;
}

@media screen and (max-width: 970px) {
  .form-container {
    margin: 0 2rem 2rem 2rem;
    padding: 1rem;
  }

  .reg-input,
  .reg-btn {
    width: 80vw;
  }

  .reg-input-groups {
    gap: unset;
  }
  .reg-msg{
    h2{
      font-size: 18px;
    }
  }
}
