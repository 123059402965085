.announcment-header {
  margin-top: 2rem;
}
.announcment-conatainer {
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}
.small-circle {
  width: 25px;
  height: 25px;
  background: #ffffff;
  border: 2px solid #008080;
  box-sizing: border-box;
  border-radius: 50%;
  display: inline-block;
  margin-left: 1rem;
}
.announcment {
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  gap: 1rem;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 1rem;
  cursor: pointer;
}

.optional {
  display: flex;
  line-height: 24px;
  color: #828282;
}

.optional div {
  width: 100%;
  height: 0px;
  margin-top: 0.7rem;
  border: 1px solid #bdbdbd;
}

.optional p {
  margin: 0 0.5rem;
}

.invest-card,
.dashboard-loan-card {
  height: 165px;
  width: 377px;
  border-radius: 10px;
  font-size: 14px;
  line-height: 21px;
  color: #232323;
  cursor: pointer;
}

.invest-card {
  background: #fff0f0;
}

.dashboard-loan-card {
  background: #d7ecec;
}

.announce-cards_header {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #313e3e;
}

@media screen and (max-width: 1024px){
    .announce-cards{
        gap: 1rem;
    }
}

@media screen and (max-width: 970px){
  .announcment-conatainer {
    font-size: 14px;
    line-height: 21px;
    color: #333333;
  }
  .announcment{
    height: 40px;
  }

  .small-circle{
    width: 15px;
height: 15px;
  }
}

@media screen and (min-width: 320px) {
  .menuContainer{
      height: 7rem;
        width: 10rem;
        padding: 1rem;
        background: white;
        justify-content: center;
        cursor: pointer;
        margin:  auto;
        text-decoration: none;
  }
}

@media screen and (min-width: 768px) {
  .menuContainer {
      
      width: 7rem;
     
    }
}

@media screen and (min-width: 1440px) {

}