.airtime1 {
  width: 100%;
}

.airtime-whitebg {
  width: 100%;
  box-shadow: 7px 6px 50px 10px rgba(37, 37, 37, 0.15);
  border-radius: 10px;
}

.wrapper {
  display: flex;
 width: fit-content;
 height: fit-content;
}

.container {
 display: flex;
 width: fit-content;
 height: fit-content;
 justify-content:center;
 margin: 0px;
 padding: 0px;
}

.checkee {
 flex: 1;
 height: fit-content;
 position: relative;
 display: flex;
 flex-direction: column;
 align-items:center;
 margin: 2px;
 background: #FFFFFF;
 border: 2px solid #00000011;
 padding-left: 1px;
 border-radius: 10px;
}

.airtimepurchase1-select_acc {
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  height: 43px;
  background: #e2f3f3;
  border: 1px solid #79b0b0;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
}


.network1 {
  position: relative;
  width: 48px;
  height: 50px;
  margin: 1px 1px;
  margin-left: -3px;
  cursor: pointer;
}

.network {
  position: relative;
  width: 50px;
  height: 50px;
  margin: 1px 1px;
  margin-left: -5px;
  cursor: pointer;
}


input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -3px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -3px;
  left: -1px;
  position: relative;
  background-color: #79b0b0;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #489494;
}


@media screen and (max-width: 480px) {
  .airtime1 {
    width: 98%;
  }

  .wrapper {
    display: flex;
   flex-direction: column;
  }

  .airtimepurchase1-select_acc {
    font-size: 10px;
    line-height: 10px;
  }
  
}
