.sendmoney2 {
  width: 60%;
}

.review-items {
  font-size: 18px;
  line-height: 108.5%;
  font-weight: bold;
  opacity: 0.8;
}

.review-previous {
  opacity: 1;
}

@media screen and (max-width: 970px) {
.sendmoney2{
    width:100%;
}    
}
