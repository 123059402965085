.sendmoney1 {
  width: 60%;
}

.sendmoney-whitebg {
  width: 100%;
  box-shadow: 7px 6px 50px 10px rgba(37, 37, 37, 0.15);
  border-radius: 10px;
}

.btn-sendmoney1 {
  width: 100%;
  height: 59px;
  box-shadow: 0px 10px 25px 5px rgba(0, 128, 128, 0.2);
  border-radius: 10px;
}

.new-transact {
  font-weight: 600;
  font-size: 36px;
  line-height: 108.5%;
  color: #008080;
}

.sendmoney-select_acc-label {
  font-size: 16px;
  line-height: 24px;
}

.sendmoney1-select_acc {
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  height: 43px;
  background: #e2f3f3;
  border: 1px solid #79b0b0;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
}

.beneficiary-icon {
  font-size: 24px;
  margin: 1rem 0.5rem 1rem 1rem;
}

.beneficiary-unselected {
  background: #f3f5fc;
  border: 1px solid #d6dcdc;
  color: #757575 !important;
}

.select_acc-circle {
  width: 10px;
  height: 10px;
  padding: 5px;
  background-color: #79b0b0;
  border: 5px solid #008080;
  border-radius: 50%;
  margin: 1rem;
}

#amount-input,
#accNumber-input,
.select-input {
  width: 100%;
  height: 43px;
  background: #f3f5fc;
  border: 1px solid #d6dcdc;
  padding: 0 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
}

#amount-input:focus,
#accNumber-input:focus {
  outline: 1px solid #008080;
}

.sendmoney-textarea {
  background: #f3f5fc;
  border: 1px solid #d6dcdc;
}

// /* Chrome, Safari, Edge, Opera */ remove arrows
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

///* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 970px) {
  .sendmoney1 {
    width: 95vw;
  }

  .sendmoney1-select_acc {
    font-size: 14px;
    line-height: 21px;
  }

  .beneficiary-type {
    font-size: 12px;
    line-height: 18px;
  }
}
