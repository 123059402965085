.right-column_header {
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: #333333;
}

.right-column_dropdown {
  width: 75px;
  height: 42px;
  box-sizing: border-box;
  border-radius: 5px;
}

.dropdown-circle {
  width: 20px;
}

.percent-complete {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}

.img-area {
  position: relative;
}

.big-img-container {
  width: 125px;
  height: 125px;
  position: absolute;
  // fit the picture in the circle here
  top: 18.5%;
  left: 28.5%;
}

.dashtransact-heading h4 {
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #333333;
}

.dashtransact-heading p {
  font-size: 14px;
  line-height: 21px;
  color: #658383;
  cursor: pointer;
}

.dashtransact-heading p:hover {
  font-weight: bold;
}

.right-transaction-list_heading {
  font-size: 16px;
  line-height: 19px;
  color: #4f4f4f;
}

.right-transaction-list_subheading,
.right-transaction-amount + p {
  font-size: 12px;
  line-height: 14px;
  color: #828282;
}

.right-transaction-amount {
  font-size: 16px;
  line-height: 19px;
}

.right-transaction-bigdot{
    height: 20px;
    width: 20px;
    background-color: #008080;
    border-radius: 50%;
    margin: 0.5rem;
}
