.login-btn {
  width: 300px;
  height: 40px;
  border-radius: 10px;
}

.forget-pass {
  font-size: 12px;
  line-height: 14px;
  color: #4f4f4f;
}
.login-alert{
  width: 301px;
}
@media screen and (max-width: 970px) {
   .login-btn{
    width: 80vw;
   }
   .login-alert{
     width: 80vw;
   }
  }